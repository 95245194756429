import React from 'react';
import {BASE_URL} from '../../../validation/basic'

const ActivateApp=({step,nextStep,prevStep})=>{
	return (
			<>
				<div className="row mt-3">
					<div className="col-md-12" style={{textAlign:"center"}}>
						<div className="item-content mt-3">
							<p style={{fontSize:'18px'}}><strong>App Activate Process</strong></p>
						</div>

						<div className="row mt-3">
							<div className="col-md-3">
								<div className="app-activate-process-img">
									<img src={BASE_URL+"/assets/images/screen/step1_install.jpg"} />
								</div>
							</div>

							<div className="col-md-3">
								<div className="app-activate-process-img">
									<img src={BASE_URL+"/assets/images/screen/step2_install.jpg"} />
								</div>
							</div>

							<div className="col-md-3">
								<div className="app-activate-process-img">
									<img src={BASE_URL+"/assets/images/screen/step3_install.jpg"} />
								</div>
							</div>

							<div className="col-md-3">
								<div className="app-activate-process-img">
									<img src={BASE_URL+"/assets/images/screen/step4_install.jpg"} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
}


export default ActivateApp;