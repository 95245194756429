const BASE_URL = window.location.origin;
// const APP_URL = "https://crm.closecall.in/inhouse-call/public/api/";
const  APP_URL = "https://demo.closecall.in/inhouse-call/public/api/";
// const APP_URL = BASE_URL+'/inhouse-call/public/api/';
const getdates = (day) => {
  let today = new Date();
  let getdate = today.getDate() - day;
  let getmonth = today.getMonth() + 1;
  let getyear = today.getFullYear();
  let update_date = "";
  let update_month = "";
  if (getdate > 9) {
    update_date = getdate;
  } else if (getdate < 10) {
    update_date = "0" + getdate;
  }
  if (getmonth > 9) {
    update_month = getmonth;
  } else if (getmonth < 10) {
    update_month = "0" + getmonth;
  }

  let currdate = getyear + "-" + update_month + "-" + update_date;
  return currdate;
};
export { APP_URL, getdates, BASE_URL };

