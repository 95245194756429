import React,{useState,useEffect} from 'react';
import {Link,useParams,useLocation } from 'react-router-dom';
import Footer from '../../layout/footer';
import Master from '../../layout/master';
import Topbar from '../../layout/topbar';
import {company} from '../../validation/validator';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {APP_URL} from '../../validation/basic';
import Loader from '../../loader/loader';
import axios from 'axios';
const AddPlan=()=>{
	const [permissionlist,setPermissionlist]=useState([]);
    const [accesspermission,setAccesspermission]=useState([]);
    const [menuids,setMenuids]=useState('');
    const [backup,setBackup]=useState([]);
    const [loading,setLoading]=useState(false);
    const { search } = useLocation();
    let plan_id= new URLSearchParams(search).get("plan_id");
    const [token,setToken]=useState(localStorage.getItem('token'));
    const [titleerror,setTitleerror]=useState(true);
    const [currencytypeerror,setCurrencytypeerror]=useState(true);
    const [newpriceerror,setNewpriceerror]=useState(true);
    const [oldpriceerror,setOldpriceerror]=useState(true);
    const [colorerror,setColorerror]=useState(true);
    const [durationTypeerror,setDurationTypeerror]=useState(true);
    const [durationerror,setDurationerror]=useState(true);
    const [descriptionerror,setDescriptionerror]=useState(true);
    const [featureserror,setFeatureserror]=useState(true);
    const [plan,setPlan]=useState({
    	title:"",
    	currency_type:0,
    	new_price:"",
    	old_price:"",
    	color:"",
    	duration_type:0,
    	duration:"",
    	description:"",
    	features:""
    });
    const {title,currency_type,new_price,old_price,color,duration_type,duration,description,features}=plan;

    useEffect(()=>{
    	setToken(localStorage.getItem('token')); 
    	ViewPlan();   	
    },[]);

    const handlechange=(e)=>{
    	setPlan({...plan,[e.target.name]:e.target.value});
    }
    const validate=()=>{
    	if(title==''){
    		setTitleerror(false);
    	}else{
    		setTitleerror(true);
    	}
    	if(currency_type===0){
    		setCurrencytypeerror(false);
    	}else{
    		setCurrencytypeerror(true);
    	}
    	if(new_price==''){
    		setNewpriceerror(false);
    	}else{
    		setNewpriceerror(true);
    	}
    	if(old_price==''){
    		setOldpriceerror(false);
    	}else{
    		setOldpriceerror(true);
    	}
    	if(color==''){
    		setColorerror(false);
    	}else{
    		setColorerror(true);
    	}
    	if(duration_type===0){
    		setDurationTypeerror(false);
    	}else{
    		setDurationTypeerror(true);
    	}
    	if(duration==''){
    		setDurationerror(false);
    	}else{
    		setDurationerror(true);
    	}
    	if(description==''){
    		setDescriptionerror(false);
    	}else{
    		setDescriptionerror(true);
    	}
    	
    	if(features==''){
    		setFeatureserror(false);
    	}else{
    		setFeatureserror(true);
    	}

    	if(title==''||currency_type==0||new_price==''||old_price==''||color==''||duration_type==0||duration==''||description==''||features==''){
    		return false;
    	}else{
    		return true
    	}
    }


    const ViewPlan=async()=>{
    	if(plan_id=='' || plan_id==null){
    		return false;
    	}else{
    		try{
    			const view_plan=await axios.get(APP_URL+'view-pricing-plan/'+`${plan_id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
    			const view_plan_details = JSON.parse(JSON.stringify(view_plan.data));
    			if(view_plan_details.status==1){
    				setPlan({...plan,
    					title:view_plan_details.data.view_plan.title,
    					currency_type:view_plan_details.data.view_plan.currency_type,
    					new_price:view_plan_details.data.view_plan.new_price,
    					old_price:view_plan_details.data.view_plan.old_price,
    					color:view_plan_details.data.view_plan.color,
    					duration_type:view_plan_details.data.view_plan.duration_type,
    					duration:view_plan_details.data.view_plan.duration,
    					description:view_plan_details.data.view_plan.description,
    					features:view_plan_details.data.view_plan.features,
    					id:plan_id
    				})
    			}

    		}catch(e){
    			return false;
    		}
    	}
    }


    const planSubmit=async()=>{
    	if(validate()==true){
    		try{
    			const create_plan = await axios.post(APP_URL+'create-pricing-plan',plan,{ headers: {"Authorization" : `Bearer ${token}`}});
    			const js_data_company_create = JSON.parse(JSON.stringify(create_plan.data));           
	            if(js_data_company_create.status==1){	                         
	                toast.success('Plan Created successfull');
	            }else{
	                toast.error('something went wrong');
	            }
    		}catch(e){
    			toast.error('something went wrong');
    		}
    	}else{
    		return false;
    	}
    }

    const PlanUpdate=async()=>{
    	try{    		
    		const update_plan = await axios.post(APP_URL+'update-pricing-plan',plan,{ headers: {"Authorization" : `Bearer ${token}`}});
    		const js_data_plan_update = JSON.parse(JSON.stringify(update_plan.data));           
	            if(js_data_plan_update.status==1){	                         
	                toast.success('Plan Updated successfull');
	            }else{
	                toast.error('something went wrong');
	            }
    	}catch(e){
    			toast.error('something went wrong');
    	}
    }


   
	return (
			<>
		<div id="app">
         <div id="main" className="layout-horizontal">
           <Topbar setMenuids={setMenuids} menuids={menuids} setAccesspermission={setAccesspermission} accesspermission={accesspermission} setPermissionlist={setPermissionlist} permissionlist={permissionlist}/>
          <ToastContainer />
  <div className="content-wrapper container">            

<div className="page-content">
    <section className="row">
    	<div className="col-12 col-lg-12">
    		<div className="card">
                <div className="card-header" style={{borderBottom:'1px solid'}}>
                		<h5>{plan_id!=null?'Edit':'Add'} Pricing Plan</h5>		                   
                </div>
                <div className="card-body"> 
                	<div className="row mt-3">               	
	                	<div className="col-md-6 mt-3">
	                		<div className="row">
	                			<div className="col-md-3">
				                    <label>Title <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
				                </div>
				                <div className="col-md-9 form-group">
				                	<input type="text" className={"form-control "+(titleerror==false?'invalid':'')} name="title" onChange={(e)=>handlechange(e)} value={title}/>
				                </div>
	                		</div>                	
	                	</div>
	                	<div className="col-md-6 mt-3">
	                		<div className="row">
	                			<div className="col-md-3">
				                    <label>Currency Type <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
				                </div>
				                <div className="col-md-9 form-group">
				                	<select className={"form-control "+(currencytypeerror==false?'invalid':'')} name="currency_type" onChange={(e)=>handlechange(e)}>
				                		<option value="0" selected={currency_type===0?true:false}>Select Currency Type</option>
				                		<option value="1" selected={currency_type===1?true:false}>Rupees(₹)</option>
				                		<option value="2" selected={currency_type===2?true:false}>Dollar($)</option>
				                	</select>
				                </div>
	                		</div>                	
	                	</div>
	                	<div className="col-md-6 mt-3">
	                		<div className="row">
	                			<div className="col-md-3">
				                    <label>New Price <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
				                </div>
				                <div className="col-md-9 form-group">
				                	<input type="text" className={"form-control "+(newpriceerror==false?'invalid':'')} name="new_price" onChange={(e)=>handlechange(e)} value={new_price}/>
				                </div>
	                		</div>                	
	                	</div>
	                	<div className="col-md-6 mt-3">
	                		<div className="row">
	                			<div className="col-md-3">
				                    <label>Old Price <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
				                </div>
				                <div className="col-md-9 form-group">
				                	<input type="text" className={"form-control "+(oldpriceerror==false?'invalid':'')} name="old_price" onChange={(e)=>handlechange(e)} value={old_price}/>
				                </div>
	                		</div>                	
	                	</div>
	                	<div className="col-md-6 mt-3">
	                		<div className="row">
	                			<div className="col-md-3">
				                    <label>Color <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
				                </div>
				                <div className="col-md-9 form-group">
				                	<input type="text" className={"form-control "+(colorerror==false?'invalid':'')} name="color" onChange={(e)=>handlechange(e)} value={color}/>
				                </div>
	                		</div>                	
	                	</div>
	                	<div className="col-md-6 mt-3">
	                		<div className="row">
	                			<div className="col-md-3">
				                    <label>Duration Type <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
				                </div>
				                <div className="col-md-9 form-group">
				                	<select className={"form-control "+(durationTypeerror==false?'invalid':'')} name="duration_type" onChange={(e)=>handlechange(e)}>
				                		<option value="0" selected={duration_type===0?true:false}>Select Duration Type</option>
				                		<option value="1" selected={duration_type===1?true:false}>Days</option>
				                		<option value="2" selected={duration_type===2?true:false}>Month</option>
				                		<option value="3" selected={duration_type===3?true:false}>Year</option>
				                	</select>
				                </div>
	                		</div>                	
	                	</div>
	                	<div className="col-md-6 mt-3">
	                		<div className="row">
	                			<div className="col-md-3">
				                    <label>Duration <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
				                </div>
				                <div className="col-md-9 form-group">
				                	<input type="text" className={"form-control "+(durationerror==false?'invalid':'')}name="duration" onChange={(e)=>handlechange(e)} value={duration}/>
				                </div>
	                		</div>                	
	                	</div>

	                	<div className="col-md-6 mt-3">
	                		<div className="row">
	                			<div className="col-md-2">
				                    <label>Description <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
				                </div>
				                <div className="col-md-10 form-group">
				                	<textarea type="text" className={"form-control "+(descriptionerror==false?'invalid':'')} rows="3" name="description" onChange={(e)=>handlechange(e)} value={description}></textarea>
				                </div>
	                		</div>                	
	                	</div>
	                	<div className="col-md-12 mt-3 mb-3">	                		
		                    <label>Features <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>				                
		                	<textarea type="text" className={"form-control "+(featureserror==false?'invalid':'')} rows="5" name="features" onChange={(e)=>handlechange(e)} value={features}></textarea>				               
	                	            	
	                	</div>
                	</div>
                	<div className="col-md-12">
                	{
                		plan_id!=null?<>
                			<button className="btn btn-success" onClick={PlanUpdate}>Update</button>
                		</>	
                		:
                		<>
                		<button className="btn btn-success" onClick={planSubmit}>Submit</button>
                		</>
                	}
                	</div>
                </div>
		    </div>
    	</div>
    	
       
        
    </section>
</div>
 <Footer/>
 </div>
          
        </div>
    </div>
			</>
		)
}

export default AddPlan;