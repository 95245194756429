import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import Footer from '../../layout/footer';
import Master from '../../layout/master';
import Topbar from '../../layout/topbar';
import {company} from '../../validation/validator';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {APP_URL} from '../../validation/basic';
import Loader from '../../loader/loader';
import axios from 'axios';

const ApiResource=()=>{
	const [permissionlist,setPermissionlist]=useState([]);
    const [accesspermission,setAccesspermission]=useState([]);
    const [menuids,setMenuids]=useState('');
    const [backup,setBackup]=useState('');
    const [loading,setLoading]=useState(false);
    const [token,setToken]=useState(localStorage.getItem('token'));
    const [isAdd,setIsAdd]=useState(false);

    useEffect(()=>{
    	setToken(localStorage.getItem('token'));  
    	showgenerateKey();  
    },[isAdd]);

    /*-----------generate api key and secret key---------*/

    const generateKey=async()=>{
    	try{
    		setIsAdd(true);
    		setLoading(true);
    		const generate_api_key = await axios.get(APP_URL+'generate-key',{ headers: {"Authorization" : `Bearer ${token}`}});
    		const generated_key = JSON.parse(JSON.stringify(generate_api_key.data));
    		if(generated_key.status==1){
    			setIsAdd(false);
    			setLoading(false);
    			toast.success(generated_key.message);
    		}
    	}catch(e){
    		return toast.error('somethin went wrong');
    	}
    }


    /*--------------show api key and secret key---------*/

     const showgenerateKey=async()=>{
    	try{    
    		setLoading(true);		
    		const generate_api_key = await axios.get(APP_URL+'get-generate-key',{ headers: {"Authorization" : `Bearer ${token}`}});
    		const generated_key = JSON.parse(JSON.stringify(generate_api_key.data));
    		if(generated_key.status==1){
    			setBackup(generated_key.data.api_key);

    			setLoading(false);
    		}
    	}catch(e){
    		return toast.error('somethin went wrong');
    	}
    }

     const copysecretKey=async(index)=>{
        let id = index;       
        var TempText = document.createElement("input");
        TempText.value = id;
        document.body.appendChild(TempText);
        TempText.select();
        document.execCommand('copy');
        toast.success('copied successfull');
        document.body.removeChild(TempText);
    }

	return(
		<>
			<div id="app">
         <div id="main" className="layout-horizontal">
           <Topbar setMenuids={setMenuids} menuids={menuids} setAccesspermission={setAccesspermission} accesspermission={accesspermission} setPermissionlist={setPermissionlist} permissionlist={permissionlist}/>
          <ToastContainer />
  <div className="content-wrapper container">            

<div className="page-content">
    <section className="row">
    	<div className="col-12 col-lg-12">
    		<div className="card">
                <div className="card-header" style={{borderBottom:'1px solid'}}>
                		<h5>APIs Resource</h5>		                   
                </div>
                <div className="card-body">
                	<div className="row mt-3">   
	                	<div className="buttons">                			
	                		<button className="btn btn-success" onClick={generateKey}>Generate Key</button>	                		
	                		<a href="https://documenter.getpostman.com/view/23662410/2s9YkuXxJE" target="_blank"><button className="btn btn-warning">APIs Document</button></a>
	                	</div>
                	</div>
                	<div className="col-md-12 mt-3">
                	<table className="table table-bordered table-hover mb-0">

                		<tbody>
                		{
                			loading==true?<><tr><td colSpan="2"><Loader/></td></tr></>:
			              	<>
			              	{
                				backup.secret_key &&  
                				<>
                				<tr>
                					<th>Key </th>
                					<td className="text-wrap">{backup.key}</td><td><button className="btn btn-success btn-sm" title="copy secret key" onClick={()=>copysecretKey(backup.key)}><i className="fa fa-copy"></i></button></td>
                				</tr>
                				<tr>
                					<th>Secret Key </th>
                					<td className="text-wrap">{backup.secret_key}</td><td><button className="btn btn-success btn-sm" title="copy secret key" onClick={()=>copysecretKey(backup.secret_key)}><i className="fa fa-copy"></i></button></td>
                				</tr>
                				</>
                				
                			}
                			</>
                		}
                			
                		</tbody>
                	</table>
                	</div>
                </div>
		    </div>
    	</div>
    	
       
        
    </section>
</div>
 <Footer/>
 </div>
          
        </div>
    </div>
    </>
		)
}

export default ApiResource;

