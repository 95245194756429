import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import Footer from '../layout/footer';
import Master from '../layout/master';
import Topbar from '../layout/topbar';
import {company} from '../validation/validator';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {APP_URL} from '../validation/basic'
import axios from 'axios';

const Company = ()=>{

    const [company,setCompany]=useState('');
    const [inphidden,setInphidden]=useState('');
    const [isedit,setIsedit]=useState(false);
    const [errorcompany,setErrorcompany]=useState(true);
    const [companies,setCompanies] = useState([]);
    const [isvalid,setIsvalid]=useState(false);
    const [btndisabled,setBtndisabled] = useState(false);
    const [permissionlist,setPermissionlist]=useState([]);
    const [accesspermission,setAccesspermission]=useState([]);
    const [menuids,setMenuids]=useState('');
    const [token,setToken]=useState(localStorage.getItem('token'));

    useEffect(()=>{
        setToken(localStorage.getItem('token')); 
        loadCompany();           
    },[]);
    const handlechange = (e)=>{
        setCompany(e.target.value);
    }

    const Validatecompany = ()=>{
        if(typeof company==='undefined'){
            setErrorcompany(false);
            setIsvalid(false);
        }
        if(typeof company!=='undefined'){
            setIsvalid(true);
        }
    }


    const loadCompany = async()=>{      
        const company_list = await axios.get(APP_URL+'companies',{ headers: {"Authorization" : `Bearer ${token}`}});
        let companiesList = JSON.parse(JSON.stringify(company_list.data));
        setCompanies(companiesList.data.companies);
             
    }

    const BtnClick = async(e)=>{
        e.preventDefault();
        Validatecompany();
        if(isvalid==true){
            setBtndisabled(true);
            try{
            var form = new FormData();
            form.append('company_name',company);
            const company_create = await axios.post(APP_URL+'companies',form,{ headers: {"Authorization" : `Bearer ${token}`}});            
            var js_data_company_create = JSON.parse(JSON.stringify(company_create.data));           
            if(js_data_company_create.status==1){ 
            loadCompany();               
                toast.success('company created successfull');
            }else{
                toast.error('something went wrong');
            }
        }catch(e){
            toast.error('something went wrong');
        }
            setBtndisabled(false);
        }
    }

    const delete_company = async(index)=>{      
        let id=index;        
        if(!window.confirm('Are you sure want to delete this')){
            return false;
        }else{
            try{
                const delete_company_details = await axios.delete(APP_URL+'companies/'+`${id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
                var js_data_delete_company = JSON.parse(JSON.stringify(delete_company_details.data));
                if(js_data_delete_company.status==1){
                    toast.success('company deleted successfull');
                    loadCompany();
                }
            }catch(e){
                 toast.error('something went wrong');
            }
        }

    }

     const change_status_department = async(index)=>{
        var id = index;
        if(!window.confirm('Are you sure want to change this')){
            return false;
        }else{
            try{
                const activate_department = await axios.get(APP_URL+'companies/status/'+`${id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
                var js_data_change_status = JSON.parse(JSON.stringify(activate_department.data));
                if(js_data_change_status.status==1){
                    toast.success('status has been changed');
                    loadCompany();
                }else{
                    toast.error('something went wrong');
                }
            }catch(e){
                    toast.error('something went wrong');
            }
        }
   } 

   const edit_view_company=async(id)=>{
        try{

            const company_view= await axios.get(APP_URL+'companies/'+`${id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
            const company_data = JSON.parse(JSON.stringify(company_view.data));
            if(company_data.status==1){
                setCompany(company_data.data.company.company_name);                
                setInphidden(company_data.data.company.id);
                setIsedit(true);
            }

        }catch(e){
            return false;
        }

   }

   const update_company_details=async()=>{
            try{
                    setBtndisabled(true);                    
                    var form1 = new URLSearchParams();
                    form1.append('company_name',company);
                    const update_company=await axios.put(APP_URL+'companies/'+`${inphidden}`,form1,{ headers: {"Authorization" : `Bearer ${token}`}});
                    const company_update=JSON.parse(JSON.stringify(update_company.data));
                    if(company_update.status==1){
                        toast.success('company updated successfull');
                        loadCompany();
                    }else{
                        toast.error('something went wrong');
                    }

            }catch(e){
               toast.error('something went wrong');
            }
            setBtndisabled(false);

   }    
 

 /*-----------copy user id-------------*/

    const copyuserid=async(index)=>{
        let id = index;       
        var TempText = document.createElement("input");
        TempText.value = id;
        document.body.appendChild(TempText);
        TempText.select();
        document.execCommand('copy');
        toast.success('copied successfull');
        document.body.removeChild(TempText);
    }
  
	return (
	<div id="app">
         <div id="main" className="layout-horizontal">
           <Topbar setMenuids={setMenuids} menuids={menuids} setAccesspermission={setAccesspermission} accesspermission={accesspermission} setPermissionlist={setPermissionlist} permissionlist={permissionlist}/>
          <ToastContainer />
  <div className="content-wrapper container">            
<div className="page-heading">
    <h4>Company Details</h4>
</div>
<div className="page-content">
    <section className="row">
    	<div className="col-12 col-lg-5">
    	 <section id="basic-vertical-layouts">
        <div className="row match-height">
            <div className="col-md-12 col-12">
                <div className="card">                   
                    <div className="card-content">
                        <div className="card-body">
                            <form className="form form-vertical">
                                <div className="form-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <input type="hidden" value={inphidden}/> 
                                            <div className="form-group">
                                                <label for="first-name-vertical">Company Name</label>
                                                <input type="text" id="first-name-vertical" className={"form-control "+(errorcompany==false?'invalid':'')}
                                                    name="company_name" onChange={handlechange} placeholder="First Name" value={company} />
                                                  
                                                   
                                            </div>
                                        </div>
                                        
                                        
                                        <div className="col-12 d-flex justify-content-end">
                                            {
                                                btndisabled==false?
                                                 <>{isedit==false?<button className="btn btn-primary me-1 mb-1" onClick={BtnClick}>Submit</button>:<button className="btn btn-primary me-1 mb-1" onClick={update_company_details}>Update</button>}</>
                                                 :
                                                <button className="btn btn-primary me-1 mb-1" type="button" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</button>
                                                }
                                            <button type="reset"
                                                className="btn btn-light-secondary me-1 mb-1">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </section>
    	</div>
        <div className="col-12 col-lg-7"> 
        <section className="section">
        <div className="row" id="table-hover-row">
            <div className="col-12">
                <div className="card">
                   
                    <div className="card-body">
                       
                       
                        <div className="table-responsive pb-5">
                            <table className="table table-bordered table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th>NAME</th>
                                        <th>Status</th>                                        
                                        <th>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    companies.map((item,index)=>(
                                    <tr>
                                        <td className="text-bold-500">{item.company_name}</td>
                                       <td>{item.status==1?<span className="badge bg-light-success" onClick={(e)=>change_status_department(item.id)}>Activate</span>:<span className="badge bg-light-danger" onClick={(e)=>change_status_department(item.id)}>Deactivate</span>}</td>
                                        <td>
                                            <div className="buttons">
                                                           
                                                {
                                                      permissionlist && permissionlist.map((p,ind)=>(
                                                          <>
                                                          {
                                                            accesspermission.some(item1=>p.id==item1)==true?
                                                            <>                                                          
                                                                {p.permission=='Edit'?<button className="btn btn-success rounded-pill btn-custom" onClick={()=>edit_view_company(item.id)}><i className="fa fa-pencil"></i></button>:null}
                                                                {p.permission=='Delete'?<button className="btn btn-danger rounded-pill btn-custom" onClick={(e)=>delete_company(item.id)}><i className="fa fa-trash"></i></button>:null}
                                                                {p.permission=='Copy'?<button className="btn btn-warning rounded-pill btn-custom" onClick={(e)=>copyuserid(item.encrypt_company_id)} title="copy user id"><i className="fa fa-copy"></i></button>:null}
                                                        </>
                                                        :null
                                                        
                                                    }
                                                    </>
                                                        ))
                                                    }

                                                
                                            </div>
                                        </td>                                        
                                    </tr> 
                                ))
                                }                                 
                                  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> 
        </div>
        
    </section>
</div>
 <Footer/>
 </div>
          
        </div>
    </div>
	);
	
}

export default Company;