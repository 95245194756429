import React,{useState,useEffect} from 'react';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {APP_URL} from '../../validation/basic';
import Loader from '../../loader/loader';
import axios from 'axios';
const APIsTemplates=(props)=>{
	const [titleError,setTitleError]=useState(true);
	const [typeError,setTypeError]=useState(true);
	const [apiKeyError,setApiKeyError]=useState(true);
	const [apiUrlError,setApiUrlError]=useState(true);
	const [token,setToken]=useState(localStorage.getItem('token'));
	const [apiData,setApiData]=useState({
		title:props.apisviewData!=null?props.apisviewData.title!=null?props.apisviewData.title:'':'',
		type:props.apisviewData!=null?props.apisviewData.type!=null?props.apisviewData.type:0:0,
		api_key:props.apisviewData!=null?props.apisviewData.api_key!=null?props.apisviewData.api_key:'':'',
		api_url:props.apisviewData!=null?props.apisviewData.api_url!=null?props.apisviewData.api_url:'':'',
		id:props.apisviewData!=null?props.apisviewData.id!=null?props.apisviewData.id:'':''
	})
	const {title,type,api_key,api_url}=apiData;
	useEffect(()=>{
		setToken(localStorage.getItem('token')); 
	},[]);
	/*-------handle change data-------*/

	const HandleChangeData=(e)=>{
		setApiData({...apiData,[e.target.name]:e.target.value});
	}

	const back_apis=()=>{
		props.setIsAdd(false);
	}

	/*-------validate data-----*/
	const validateData=()=>{
		if(title==''){
			setTitleError(false);
		}else{
			setTitleError(true);
		}
		if(type==0){
			setTypeError(false);
		}else{
			setTypeError(true);
		}
		if(api_key==''){
			setApiKeyError(false);
		}else{
			setApiKeyError(true);
		}
		if(api_url==''){
			setApiUrlError(false);
		}else{
			setApiUrlError(true);
		}
		if(title=='' || type==0 || api_key=='' || api_url==''){
			return false;
		}else{
			return true;
		}
	}

	/*----------submit api details----------*/

	const submitData=async()=>{
		if(validateData()==true){
			try{
				const apis_cred = await axios.post(APP_URL+'add-third-paryt-apis',apiData,{ headers: {"Authorization" : `Bearer ${token}`}});
				const apis_cred_data = JSON.parse(JSON.stringify(apis_cred.data));
				if(apis_cred_data.status==1){
					props.setCheckAdd(true);
					props.setIsAdd(false);
					toast.success('APIs credentials added successfull');
				}
			}catch(e){
				toast.error('something went wrong');
				return false;
			}
		}else{
			return false;
		}
	}

	/*-----------update apis data---------*/

	const updateApisData=async()=>{
		if(validateData()==true){
			try{
				const apis_cred = await axios.post(APP_URL+'update-third-party-apis',apiData,{ headers: {"Authorization" : `Bearer ${token}`}});
				const apis_cred_data = JSON.parse(JSON.stringify(apis_cred.data));
				if(apis_cred_data.status==1){
					props.setCheckAdd(true);
					props.setIsAdd(false);
					props.setIsEdit(false);
					toast.success('APIs credentials updated successfull');
				}
			}catch(e){
				toast.error('something went wrong');
				return false;
			}
		}else{
			return false;
		}
	}
	return(
		<>
		<div className="card-body">
			<button type="button" className="btn btn-success btn-sm mt-4" onClick={back_apis}><i className="fa fa-angle-left" ></i></button>
			<hr/>
			<div className="row mt-4">				
					 <div className="col-lg-6 mb-2">
                                <label className="mb-2">Title <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
                                <input type="text" className={"form-control"+(titleError==false?' invalid':'')} name="title" placeholder="Title" onChange={(e)=>HandleChangeData(e)} value={title}/>
	
				</div>
					 <div className="col-lg-6 mb-2">
                            <label className="mb-2">Type <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
                                <select type="text" className={"form-control"+(typeError==false?' invalid':'')} name="type" onChange={(e)=>HandleChangeData(e)}>
                                	<option value="0">Select APIs</option>
                                	{
                                		type==1?<>
                                			<option value="1" selected>IndiaMart</option>
		                                	<option value="2">Just Dial</option>
		                                	<option value="3">Other</option>
                                		</>
                                		:type==2?<>
                                		<option value="1" >IndiaMart</option>
		                                	<option value="2" selected>Just Dial</option>
		                                	<option value="3">Other</option>
                                		</>
                                		:type==3?<>
                                			<option value="1" >IndiaMart</option>
		                                	<option value="2" >Just Dial</option>
		                                	<option value="3" selected>Other</option>
                                		</>
                                		:<>
                                			<option value="1" >IndiaMart</option>
		                                	<option value="2" >Just Dial</option>
		                                	<option value="3" selected>Other</option>
                                		</>
                                	}
                                	
                                </select>                      
				</div>
				 <div className="col-lg-12 mb-2">                       
                      <label className="mb-2" >API Key <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
                      <input type="text" className={"form-control"+(apiKeyError==false?' invalid':'')} name="api_key" onChange={(e)=>HandleChangeData(e)} value={api_key}/>                    
                         		
				</div>
				<div className="col-lg-12 mb-2">                       
                      <label className="mb-2">API URL <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
                      <input type="text" className={"form-control"+(apiUrlError==false?' invalid':'')} name="api_url" onChange={(e)=>HandleChangeData(e)} value={api_url}/>                    
                         		
				</div>
				{
					props.isEdit==false?
					<>
					<div className="col-lg-12 mt-3 mb-1">                       
                    <button className="btn btn-success" onClick={submitData}>Submit</button>                 
                         		
					</div>
					</>
					:
					<>
						<div className="col-lg-12 mt-3 mb-1">                       
		                    <button className="btn btn-success" onClick={updateApisData}>Update</button>                 
		                         		
						</div>
					</>
				}
				

				<p className="mt-2"><strong>Note:</strong> If you are implementing India mart APIs then you have to re-generate api key after 15 days forever.</p>
			</div>
			</div>
		</>
		)
}
export default APIsTemplates;