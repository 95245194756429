import React from 'react';
import {BASE_URL} from '../../../validation/basic';

const Permission=({step,nextStep,prevStep})=>{
	return (
			<>
				<div className="row mt-3">
					<div className="col-md-12" style={{textAlign:"center"}}>
							<div className="item-content mt-3">
								<p style={{fontSize:'18px'}}><strong>Provide all required Permissions</strong></p>
							</div>
						</div>

						<div className="row mt-3">
							<div className="col-md-3 mb-3">
								<div className="app-activate-process-img">
									<img src={BASE_URL+"/assets/images/screen/permission1.jpg"} />
								</div>
							</div>

							<div className="col-md-3 mb-3">
								<div className="app-activate-process-img">
									<img src={BASE_URL+"/assets/images/screen/permission2.jpg"} />
								</div>
							</div>

							<div className="col-md-3 mb-3">
								<div className="app-activate-process-img">
									<img src={BASE_URL+"/assets/images/screen/permission3.jpg"} />
								</div>
							</div>

							<div className="col-md-3 mb-3">
								<div className="app-activate-process-img">
									<img src={BASE_URL+"/assets/images/screen/permission4.jpg"} />
								</div>
							</div>

							
						</div>
					
				</div>

			</>
		)
}


export default Permission;