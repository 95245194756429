import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import Footer from '../../layout/footer';
import Master from '../../layout/master';
import Topbar from '../../layout/topbar';
import {company} from '../../validation/validator';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {APP_URL} from '../../validation/basic';
import Loader from '../../loader/loader';
import axios from 'axios';
import APIsTemplates from './APITemplates';
const ManageApis=()=>{
	const [permissionlist,setPermissionlist]=useState([]);
    const [accesspermission,setAccesspermission]=useState([]);
    const [menuids,setMenuids]=useState('');
    const [apiData,setApiData]=useState([]);
    const [loading,setLoading]=useState(false);
    const [isAdd,setIsAdd]=useState(false);
    const [isEdit,setIsEdit]=useState(false);
    const [checkAdd,setCheckAdd]=useState(false);
    const [apisviewData,setApisviewData]=useState(null);
    const [token,setToken]=useState(localStorage.getItem('token'));
    useEffect(()=>{
    	setToken(localStorage.getItem('token')); 
    	loaddata();   	
    },[checkAdd]);

    /*--------add new apis function--------*/
    const AddNewApis=()=>{
    	setIsAdd(true);
    	setCheckAdd(false);
    	setIsEdit(false);
    	setApisviewData(null);
    }

    /*---------list third party apis credentials--------*/

     const loaddata= async()=>{      
        const company_list = await axios.get(APP_URL+'get-third-party-apis',{ headers: {"Authorization" : `Bearer ${token}`}});
        let companiesList = JSON.parse(JSON.stringify(company_list.data));
        if(companiesList.status==1){
            setApiData(companiesList.data.apis_cred_list); 
        }                    
    }

    /*------------activate and deactivate third party apis---------*/

    const changeStatus=async(index,status)=>{
    	if(window.confirm("Are you sure want to change this")){
    		try{
    			 setCheckAdd(false);
    			 if(status==1){
                    var updated_status=0;
                }else{
                    var updated_status=1;
                }
                var form = new FormData();
                form.append('status',updated_status);
                form.append('id',index);

    			const apis_status = await axios.post(APP_URL+'activate-third-party-apis',form,{ headers: {"Authorization" : `Bearer ${token}`}});
    			const apis_js_data = JSON.parse(JSON.stringify(apis_status.data));
    			if(apis_js_data.status==1){
    				 setCheckAdd(true);
    				toast.success("status has been changed");
    			}
    		}catch(e){
    			toast.error("Something went wrong");
    		}
    	}else{
    		return false;
    	}
    }

    /*-------------delete third party apis----------*/

    const delete_third_pary = async(index)=>{      
        let id=index;        
        if(!window.confirm('Are you sure want to delete this')){
            return false;
        }else{
            try{
                 setCheckAdd(false);
                const delete_company_details = await axios.get(APP_URL+'delete-third-party-apis/'+`${id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
                var js_data_delete_company = JSON.parse(JSON.stringify(delete_company_details.data));
                if(js_data_delete_company.status==1){
                     setCheckAdd(true);
                    toast.success('Apis deleted successfull');                   
                }
            }catch(e){
                 toast.error('something went wrong');
            }
        }

    }

    /*-----------------view manage apis--------*/

     const loadApisViewData= async(id)=>{  
     	setCheckAdd(false);    
        const company_list = await axios.get(APP_URL+'view-third-party-apis/'+`${id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
        let companiesList = JSON.parse(JSON.stringify(company_list.data));
        if(companiesList.status==1){
        	setIsAdd(true)
        	setIsEdit(true);
            setApisviewData(companiesList.data.apis_cred_list); 
        }                    
    }
	return(
		<>
		<div id="app">
		   <div id="main" className="layout-horizontal">
		      <Topbar setMenuids={setMenuids} menuids={menuids} setAccesspermission={setAccesspermission} accesspermission={accesspermission} setPermissionlist={setPermissionlist} permissionlist={permissionlist}/>
		      <ToastContainer />
		      <div className="content-wrapper container">
		         <div className="page-content">
		            <section className="row">
		               <div className="col-12 col-lg-12">
		                  <div className="card">
		                     <div className="card-header" style={{borderBottom:'1px solid'}}>
		                     <h5>Manage Third Party APIs</h5>
		                  </div>
		                  {
		                  	isAdd!=false?<><APIsTemplates isAdd={isAdd} setIsAdd={setIsAdd} setCheckAdd={setCheckAdd}  apisviewData={apisviewData} setIsEdit={setIsEdit} isEdit={isEdit}/></>:<>		                  
		                  <div className="card-body">
		                     <div className="col-md-12 mt-3">                		
		                        <button className="btn btn-success" onClick={AddNewApis}><i className="fa fa-plus"></i> Add New APIs</button>
		                     </div>
		                     <div className="col-md-12 mt-3 table-responsive">
		                        <table className="table table-bordered table-hover mb-0">
		                           <thead>
		                              <tr>
		                                 <th >Title</th>
		                                 {/*<th>APIs Info</th>*/}
		                                 <th>APIs Type</th>
		                                 <th>Status</th>
		                                 <th>Action</th>
		                              </tr>
		                           </thead>
		                           <tbody>
		                           {
		                           	apiData.map((item)=>(
		                           		<>
		                           		<tr>
		                           			<td>{item.title}</td>
		                           			{/*<td>
		                           						                           			<div style={{width:'100%'}}>
		                           						                           				<strong>API URL : </strong>{item.api_url} <br/>
		                           						                           				<strong>API Key :</strong>{item.api_key} 
		                           						                           			</div>
		                           					                           			</td>*/}
		                           			<td>{item.type==1?'India Mart':item.type==2?'Just Dial':'Other'}</td>
		                           			 <td>{item.status==1?<span className="badge bg-light-success">Activate</span>:<span className="badge bg-light-danger">Deactivate</span>}</td>
		                           			<td>
		                           				 <div className="buttons">
                                                           
                                                {
                                                      permissionlist && permissionlist.map((p,ind)=>(
                                                          <>
                                                          {
                                                            accesspermission.some(item1=>p.id==item1)==true?
                                                            <>  
                                                                {p.permission=='Activate'?<button className="btn btn-warning rounded-pill btn-custom" onClick={()=>changeStatus(item.id,item.status)}><i className="fa fa-ban"></i></button>:null}                                                        
                                                                {p.permission=='Edit'?<button className="btn btn-success rounded-pill btn-custom" onClick={()=>loadApisViewData(item.id)}><i className="fa fa-pencil"></i></button>:null}
                                                                {p.permission=='Delete'?<button className="btn btn-danger rounded-pill btn-custom" onClick={()=>delete_third_pary(item.id)}><i className="fa fa-trash"></i></button>:null}
                                                        
                                                        </>
                                                        :null
                                                        
                                                    }
                                                    </>
                                                        ))
                                                    }

                                                
                                            </div>
		                           			</td>
		                           		</tr>
		                           		</>
		                           		))
		                           }
		                           </tbody>
		                        </table>
		                     </div>
		                  </div>
		                     </>
		           }
		               </div>

		         </div>
		         </section>
		      </div>
		      <Footer/>
		   </div>
		</div>
		</div>
		</>
		)
}

export default ManageApis;