import React from 'react';

const Navstep=({step})=>{

		return(<>
			 <div id="wizard-progress">
			    <ol className="step-indicator">
			        <li className={"nvstep "+(step==1?'active':'')}>
			            <div className="step">1</div>
			            
			        </li>
			        <li className={"nvstep "+(step==2?'active':'')}>
			            <div className="step">2</div>
			           
			        </li>
			        <li className={"nvstep "+(step==3?'active':'')}>
			            <div className="step">3</div>
			            
			        </li>
			        <li className={"nvstep "+(step==4?'active':'')}>
			            <div className="step">4</div>
			            
			        </li>
			    </ol>
			</div>
			</>
			);	
}

export default Navstep;