import React from 'react';
import QRCode from "react-qr-code";
import {BASE_URL} from '../../../validation/basic'

const Connect=({step,nextStep,prevStep,connectcode})=>{
	return (
				<>
					<div className="row mt-3">
					<div className="col-md-12" style={{textAlign:"center"}}>
						<div className="item-content mt-3">
							<p style={{fontSize:'18px'}}><strong>Please enter device code or scan QR code to connect device to CRM</strong></p>
						</div>
					</div>

						<div className="row mt-3">
						<div className="col-md-3"></div>							
							<div className="col-md-3">
								<div className="app-activate-process-img">
									<img src={BASE_URL+"/assets/images/screen/connect.jpg"} />
								</div>
							</div>
							<div className="col-md-3 text-center" >
								<div className="connect-device-text">
									<p style={{marginBottom:'30px'}}><strong>Device Connect Code</strong></p>									
									<p><span className="connectiondevice">{connectcode}</span></p>									
								</div>
								<span className="devider">OR</span>
								<div className="connect-device-qr-code" style={{textAlign:'center'}}>
									<p>Scan QR Code</p>
									<QRCode
									size={150}
									value={connectcode}
									
									/>
								</div>
							</div>
							<div className="col-md-3"></div>
						</div>
					
				</div>
				</>
			)
				
}


export default Connect;