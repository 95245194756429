import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';

const footer = ()=>{   
	return (
		 <footer className="fixed-bottom ">
                <div className="footer clearfix mb-0 text-white bg-secondary px-5 py-2">
                    <div className="float-start">
                        <p className="m-0">{new Date().getFullYear()} @ <a href="https://closecall.in" className="text-white" target="_blank">CloseCall.in</a></p>
                    </div>
                    <div className="float-end">
                        <p className="m-0">design and develop  <span className="text-danger"><i className="bi bi-heart"></i></span> by <a
                                href="https://corpbiz.io" className="text-white"  target="_blank">Corpbiz.io</a></p>
                    </div>
                </div>
            </footer>
		);
}

export default footer;