import React from 'react';

const Loader = ()=>{
	return (
		<>
			<div className="loader" style={{color:'purple',width:'50px',position:'relative',left:'50%',right:'50%'}}></div>
		</>
		)
}

export default Loader;