import React,{useState} from 'react';
import Install from './install';
import Permission from './permission';
import Navstep from './navstep';
import Connect from './connect';
import ActivateApp from './activate';

const Finalprocess=({step,nextStep,prevStep,departments,selectdept,employee,setEmployees,setSelectdept,setConnectcode,connectcode})=>{
	
	if(step==1){
		return (
			<>
				<Navstep step={step}/>
				<Install nextStep ={nextStep} departments={departments} employee={employee} setEmployees={setEmployees} setConnectcode={setConnectcode} connectcode={connectcode}/>				
			</>
			)
	}else if(step==2){
		return (
			<>
				<Navstep step={step}/>
				<ActivateApp nextStep={nextStep} prevStep={prevStep}/>				
			</>
			)
	}else if(step==3){
		return (
			<>
				<Navstep step={step}/>
				<Permission nextStep={nextStep} prevStep={prevStep}/>
			</>
			)
	}else if(step==4){
		return (
			<>
				<Navstep step={step}/>
				<Connect nextStep={nextStep} prevStep={prevStep} connectcode={connectcode}/>
			</>
			)
	}
}


export default Finalprocess;