import React,{useState,useEffect} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import Topbar from '../../../layout/topbar';
import Footer from '../../../layout/footer';
import {APP_URL} from '../../../validation/basic';
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddPermission=()=>{
	const [permissions,setPermissions] = useState({
		permission_name:'',
		parent_menu:'0',
		permission_url:''		
	});

	const [permissionerror,setPermissionerror] = useState(false);
	const [parenterror,setParenterror] = useState(false);
	const [urlerror,setUrlerror] = useState(false);
	const [token,setToken]=useState(localStorage.getItem('token'));	

	let navigate = useNavigate ();

	const [listmenu,setListmenu] =useState([]);
	const {permission_name,parent_menu,permission_url} = permissions;

	useEffect(()=>{
		setToken(localStorage.getItem('token'));
		getmenus();
	},[])
	const getmenus = async()=>{
		try{
				const menusdata = await axios.get(APP_URL+'menus',{ headers: {"Authorization" : `Bearer ${token}`}});
				let menuslist = JSON.parse(JSON.stringify(menusdata.data));
				if(menuslist.status==1){
					setListmenu(menuslist.data.menus);
				}

		}catch(e){
			return false;
		}
	}

	const handlechange = (e)=>{
		setPermissions({...permissions,[e.target.name]:e.target.value});
	}

	const validate_fields = ()=>{

		if(permissions.permission_name==''){
			setPermissionerror(true);
		}else{
			setPermissionerror(false);
		}
		
		if(permissions.parent_menu=='0'){
			setParenterror(true);
		}else{
			setParenterror(false);
		}

		if(permissions.permission_url==''){
			setUrlerror(true);
		}else{
			setUrlerror(false);
		}
		
	}

	const MenuSubmit = async()=>{
		validate_fields();
		if(permission_name!=''&& parent_menu!='0' &&permission_url!=''){
			try{	
					let form = new FormData();
					form.append('permission',permission_name);
					form.append('slug',permission_url);
					form.append('menu_id',parent_menu);
					
					const response = await axios.post(APP_URL+'permissions',form,{ headers: {"Authorization" : `Bearer ${token}`}});
					let response_data = JSON.parse(JSON.stringify(response.data));
					if(response_data.status==1){
						toast.success('Permission created successfull');
						navigate('/master/manage/permission');							
					}

			}catch(e){
				toast.error('something went wrong');
				return false;
			}
		}else{
			return false;
		}	
	}

	console.log(permissions);


	return (
		<>
			<div id="app">
		        <div id="main" className="layout-horizontal">
		          <Topbar/>
		          <ToastContainer/>
					  <div className="content-wrapper container">           
					
					<div className="page-content">
					    <section className="row">    	
				        <div className="col-12 col-lg-12"> 
				        <section className="section">
				        <div className="row" id="table-hover-row">
				            <div className="col-12">
				                <div className="card">
				                    <div className="card-header" style={{borderBottom:'1px solid'}}>
				                    <h5>Add New Permission</h5>
				                 		                   
				                    </div>
				                    <div className="card-body mt-4">                     
				                       	
				                        <div className="row">
				                        	<div className="col-md-2"></div>
				                        	<div className="col-md-8">
				                        		<div className="row">
					                        		<div className="col-sm-3" style={{textAlign:'right'}}>
								                    <label>Permission Name</label>
									                </div>
									                <div className="col-sm-9 form-group">
									                    <input type="text" className={"form-control "+(permissionerror==true?'invalid':null)} name="permission_name" placeholder="permission" onChange={(e)=>handlechange(e)} value={permission_name}/>
									                </div>
								                </div>

								                <div className="row">
					                        		<div className="col-sm-3" style={{textAlign:'right'}}>
								                    <label>Select Menu</label>
									                </div>
									                <div className="col-sm-9 form-group">
									                    <select type="text" className={"form-control "+(parenterror==true?'invalid':null)} name="parent_menu" onChange={(e)=>handlechange(e)}>
									                    	<option value="0">parent menu</option>
									                    	{
									                    		listmenu.map((item,index)=>(
									                    			<>
									                    				<option value={item.id}>{item.menu_name}</option>
									                    			</>
									                    		))
									                    	}
									                    </select>
									                </div>
								                </div>


								                <div className="row">
					                        		<div className="col-sm-3" style={{textAlign:'right'}}>
								                    <label>Permission url</label>
									                </div>
									                <div className="col-sm-9 form-group">
									                    <input type="text" className={"form-control "+(urlerror==true?'invalid':null)} name="permission_url" placeholder="enter permission url" onChange={(e)=>handlechange(e)} value={permission_url}/>
									                </div>
								                </div>

								                

								                <div className="col-md-12" style={{textAlign:'right'}}>
								                	<button className="btn btn-success" onClick={MenuSubmit}>Submit</button>
								                </div>


				                        	</div>
				                        	<div className="col-md-2"></div>
				                        </div>
				                    </div>
				                </div>
				            </div>
				        </div>
				    </section> 
				        </div>
				        
				    </section>
				</div>
				  <Footer/>
				 </div>
				         
				        </div>
		    </div>
		</>
		)
}


export default AddPermission;