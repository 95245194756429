import React from 'react';
import {Link} from 'react-router-dom';
import Topbar from '../../layout/topbar';


const addemployee = ()=>{
	return (
				<div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar/>
  <div className="content-wrapper container">           
<div className="page-heading">
    <h4>Add New Employee</h4>
</div>
	
	
</div>
</div>
</div>
		);
}


export default addemployee;